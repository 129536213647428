var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid mt-5"},[_c('div',[_c('card',{staticClass:"no-border-card",attrs:{"body-classes":"px-0 pb-1","footer-classes":"pb-2"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$t("PURCHASES_PAYMENTS.PURCHASES_PAYMENTS_LIST"))+" ")])]),_c('div',{staticClass:"col-6 text-right"},[(
                _vm.$currentUserCan(_vm.$permissions.PERM_CREATE_PURCHASES_PAYMENTS)
              )?_c('base-button',{attrs:{"type":"primary","icon":"","size":"sm"},on:{"click":function($event){return _vm.addPurchasesPayment()}}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fas fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(_vm._s(_vm.$t("PURCHASES_PAYMENTS.ADD_PURCHASES_PAYMENT")))])]):_vm._e(),(
                _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_PURCHASES_PAYMENTS)
              )?_c('notification-subscription',{attrs:{"objectType":'purchasesPayments',"events":{
                CREATE: _vm.$t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: _vm.$t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: _vm.$t('NOTIFICATIONS.EVENT_DELETE'),
              }}}):_vm._e()],1)])]),_c('purchasesPayment-list-table')],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }